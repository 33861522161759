import React from "react";
import { createHashRouter } from "react-router-dom";
import ResumePage from "./pages/dash/reunioes/resume/Index";
import SuccessPage from "pages/success";
import InvalidPage from "pages/invalid";
import DashboardResumeTudo from "pages/dash";
import Layout from "components/layout";
import Reunioes from "pages/dash/reunioes";
import Convidar from "pages/dash/convidar";
import Cobranca from "pages/dash/configuracoes/cobranca";
import Equipe from "pages/dash/configuracoes/equipe";
import ReuniaoList from "pages/dash/reunioes/list";
import OAuthSignInPage from "pages/signin";
import NotFound from "components/NotFound";
import ResumePageNoAuth from "pages/dash/reunioes/resume_noauth/Index";
import { RedirectToDashboard } from "components/RedirectToDash";
import { ProtectedRoute } from "components/ProtectedRoute/intex";

export const routesName = {
  main: "/",
  login: "/login",
  success: "/sucesso",
  invalidSolicitation: "/solicitacao-invalida",
  dashboard: "/dashboard",
  reunioes: "reunioes", // Caminho relativo
  convidar: "convidar",
  cobranca: "configuracoes/cobranca",
  equipe: "configuracoes/equipe",
};

export const routes = createHashRouter([
  {
    path: "/",
    element: <RedirectToDashboard />,
  },
  {
    path: '/meeting/:meetingId/transcript',
    element: <ResumePageNoAuth />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: routesName.success,
    element: <SuccessPage />,
  },
  {
    path: routesName.invalidSolicitation,
    element: <InvalidPage />,
  },
  {
    path: routesName.login,
    element: <OAuthSignInPage />,
  },
  {
    path: routesName.dashboard,
    element: <DashboardResumeTudo />,
    children: [
      {
        path: "",
        element: <Layout />,
        children: [
          {
            path: "",
            element: <Reunioes />,
            children: [
              {
                path: "",
                element: <ReuniaoList />,
              },
              {
                path: ":meetingId",
                element: <ProtectedRoute><ResumePage /></ProtectedRoute>,
              },
            ],
          },
          {
            path: routesName.convidar,
            element: <Convidar />,
          },
          {
            path: routesName.cobranca,
            element: <Cobranca />,
          },
          {
            path: routesName.equipe,
            element: <Equipe />,
          },
        ],
      },
    ],
  },
]);
