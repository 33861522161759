import { ExtensionSync } from "components/ExtensionSync";
import React, { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";


export const RedirectToDashboard: React.FC = () => {

  const [isCheck, setIsCheck] = useState(false);
  const accessToken = localStorage.getItem('access_token')
  const location = useLocation();

  const handleSyncFinish = (success: boolean) => {
    setIsCheck(success);
  };

  if (!isCheck) {
    // Exibe tela de carregamento durante a sincronização
    return <ExtensionSync onFinish={handleSyncFinish} />;
  }

  if (accessToken) {
    // Redireciona para login se não autenticado
    return <Navigate to="/dashboard" replace />;
  }

  return <Navigate to="/login" state={{ from: location }} replace />;
};
