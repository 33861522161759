import axios from "axios";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("access_token");
    const publicRoutes = [/^\/meeting\/[^/]+\/transcript$/];
    const currentHash = window.location.hash.replace("#", ""); // Remove o '#' do hash
    const isPublicRoute = publicRoutes.some((route) => route.test(currentHash));

    if (isPublicRoute) {
      return config;
    }

    if (accessToken) {
      config.headers.Authorization = accessToken;
    } else {
      window.location = "/" as unknown as Location;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(undefined, async (err) => {
  if (err.response.status === 401) {
    window.location = "/" as unknown as Location;
  }

  return Promise.reject(err);
});

export default api;
