import React from 'react';
import { Button } from '@mui/material';
import { GoogleLoginLogo } from 'static/images';
import './style.scss'

const GoogleLoginButton: React.FC = () => {
    const login = () => {
        return window.open("https://app.app.resumetudo.com/login-google?state=login_dash_first");
    }
    return (
        <div className='google-login'>
            <Button variant="outlined" onClick={() => login()} sx={{width: '100%'}}><img width={32} src={GoogleLoginLogo} alt='google login logo' style={{margin: '0 8px 0'}}/><span>Entrar com o Google</span> </Button>
        </div>

    );
};

export default GoogleLoginButton;
