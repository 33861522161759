import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Collapse, Divider, Grid2, Stack } from "@mui/material"
import Loading from "components/Loading"
import MeetingCard from "components/MeetingCard"
import { MeetingList } from "utils/types";
import { getMeetings, getPaymentLink } from "utils/services";
import { PageContainer, useSessionStorageState } from "@toolpad/core";
import { LoadingButton } from "@mui/lab";
import { useGlobalState } from "components/GlobalProvider";

const ReuniaoList = () => {
    const { user } = useGlobalState();
    const [meetings, setMeetings] = useState<MeetingList | undefined>();
    const [loading, setLoading] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [alertIsClosed, setAlertIsClosed] = useSessionStorageState('alertIsClosed', false);
    const [_, setOpen] = useState(false)

    const fetchMeetings = async (page: number) => {
        setLoading(true);
        try {
            const data = await getMeetings(page);
            setMeetings((prevMeetings) => ({
                ...prevMeetings,
                items: [
                    ...(prevMeetings?.items || []),
                    ...data.items
                ],
                total: data.total,
                page: data.page,
                page_size: data.page_size,
                total_pages: data.total_pages
            }));
        } finally {
            setLoading(false);
            setIsFetching(false);
        }
    };

    const fetchMoreMeetings = async () => {

        setIsFetching(true);
        const nextPage = currentPage + 1;
        setCurrentPage(nextPage);
        try {
            const data = await getMeetings(currentPage + 1);
            setMeetings((prevMeetings) => ({
                ...prevMeetings,
                items: [
                    ...(prevMeetings?.items || []),
                    ...data.items
                ],
                total: data.total,
                page: data.page,
                page_size: data.page_size,
                total_pages: data.total_pages
            }));
        } finally {
            setLoading(false);
            setIsFetching(false);
        }
    };

    useEffect(() => {
        // if(accessToken) {
        fetchMeetings(currentPage);
        // }
    }, []);

    const isLastPage = (meetings?.page ?? 0) >= (meetings?.total_pages ?? 0);
    const generateLink = (planId: string) => {
        setOpen(true)
        getPaymentLink(planId).then((response) => {
            setOpen(false)
            window.open(response.data.payment_link, '_blank')
        })
    }
    return (
        <>
            {user?.subscription === 'pro' && <Stack sx={{ maxWidth: '1280px', width: '100%', margin: '0 auto', padding: '12px 16px' }} spacing={2}>
                <Collapse in={!alertIsClosed || false}>
                    <Alert severity="warning" onClose={() => setAlertIsClosed(true)} >
                        Atenção, seu plano PRO expira 02/01/2025. Contrate agora para continuar transcrevendo e resumindo suas reuniões!
                        <Button style={{ marginLeft: '12px' }} variant="outlined" onClick={() => generateLink('pro')}>Contratar agora</Button>
                    </Alert>
                </Collapse>
            </Stack>}

            <PageContainer
                title={"Minhas Reuniões"}
                style={{ maxWidth: '1280px', textAlign: 'center' }}
            >
                <Box sx={{ flexGrow: 1 }}>
                    {/* <SearchField onSearch={handleSearch} /> */}
                    <Divider style={{ margin: '12px 0' }} />
                    <Grid2 container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        {!loading && meetings?.items.map((meeting, index) => {
                            const isBlocked =
                                (user?.subscription === "free" && index > 0) ||
                                (user?.subscription === "basic" && index >= 10);
                            return <Grid2 key={index} size={{ xs: 2, sm: 4, md: 4 }}> 
                            <MeetingCard isBlocked={isBlocked} key={meeting.id} meeting={meeting} /> </Grid2>
                        })}
                        {loading && <Loading />}
                        {!loading && meetings?.items.length == 0 &&<Alert severity="info" sx={{ width: '100%' }}>
                                                Nenhuma reunião realizada, entre em alguma chamada e comece a resumir suas reuniões.
                                            </Alert>}
                    </Grid2>
                    {!isLastPage && meetings && meetings.page < meetings.total_pages && (
                        <LoadingButton
                            loading={isFetching}
                            variant="outlined"
                            onClick={fetchMoreMeetings}
                            disabled={isFetching}
                            sx={{ marginTop: '24px' }}
                        >
                            Carregar mais
                        </LoadingButton>
                    )}
                </Box>
            </PageContainer>
        </>
    );
};

export default ReuniaoList;
