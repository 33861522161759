import React, { FC, Fragment, useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Meeting } from 'utils/types';
import { Lock } from '@mui/icons-material';
import { Box, Button, CircularProgress, Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getResumeSpeeches } from 'utils/services';
import DownloadIcon from '@mui/icons-material/Download';

interface MeetingCardProps {
  meeting: Meeting
  isBlocked: boolean
}

interface Speech {
  name: string | undefined;
  content: string | undefined;
  spoke_at: string | undefined;
  avatarUrl: string | undefined;
}

const MeetingCard: FC<MeetingCardProps> = ({ meeting, isBlocked }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false)

  // Limite de caracteres que serão exibidos
  const maxLength = 20
  const truncatedTitle = meeting.title.length > maxLength ? meeting.title.slice(0, maxLength) + '...' : meeting.title;

  // Limite de participantes que serão exibidos
  const maxVisibleParticipants = 3;
  const visibleParticipants = meeting.participants.filter((participant) =>
    participant.trim() !== "" &&
    !participant.toLowerCase().startsWith("device") &&
    !participant.startsWith("-ee")).slice(0, maxVisibleParticipants).map((participant) => {
      const maxLength = 20;
      return participant.length > maxLength
        ? participant.slice(0, maxLength) + "."
        : participant;
    });
  const remainingParticipants = meeting.participants.length - maxVisibleParticipants;

  const handleCardClick = () => {
    if (!isBlocked) { navigate(`/dashboard/${meeting.id}`); }
  };
  const [speeches, setSpeeches] = useState<Speech[]>([]);


  const generateTxt = () => {
    setOpen(true)
    getResumeSpeeches(meeting.id).then((response) => {
      setSpeeches(response.data.speeches);


      const blob = new Blob([response.data.speeches.map(
        (speech: Speech) =>
          `Nome: ${speech.name}\n` +
          `Conteúdo: ${speech.content}\n` +
          `Data: ${speech.spoke_at}\n\n`
      )
        .join("")], { type: "text/plain" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${meeting.id}-transcricao.txt`
      link.click();
    }).catch((e) => {
      if (speeches.length === 0) {
        alert("Tente novamente.");
        console.error(e)
        return;
      }
    }).finally(() => {
      setOpen(false)
    })
  };

  return (
    <Card key={meeting.id} sx={{
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%'
    }} variant="outlined" title={meeting.title}>
      <Fragment>
        <CardContent sx={{
          opacity: isBlocked ? 0.5 : 1,
          pointerEvents: isBlocked ? 'none' : 'auto',
          cursor: isBlocked ? 'not-allowed' : 'pointer'
        }} onClick={handleCardClick}>
          <Typography variant="h6">
            {truncatedTitle}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {visibleParticipants.join(', ')}
            {remainingParticipants > 0 && (
              <strong style={{ paddingLeft: 4 }}>
                +{remainingParticipants}
              </strong>
            )}
          </Typography>
          <Typography variant="body2" margin={'4px 0'}>
            {meeting.date}
          </Typography>
        </CardContent>
        <CardActions sx={{ position: 'relative' }}
        >
          {isBlocked ? (
            <Button
              variant="contained"
              color="primary"
              sx={{ margin: 'auto' }}
              onClick={() => navigate('/dashboard/configuracoes/cobranca')} // Redireciona para a tela de planos
            >
              <Lock /> DESBLOQUEAR
            </Button>
          ) : (
            <CardActions sx={{ marginLeft: 'auto' }}>
              <Button onClick={generateTxt}>Transcrição <DownloadIcon /></Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCardClick}
              >
                VISUALIZAR
              </Button>
              {/* <IconButton title={meeting.platform}>
                {meeting.platform === 'Google Meet' ? <Google /> : <Microsoft />}
              </IconButton> */}
              {/* <IconButton title='compartilhar'>
                <Share />
              </IconButton>
              <IconButton title='excluir'>
                <Delete />
              </IconButton> */}
            </CardActions>
          )}
        </CardActions>
      </Fragment>
      <DownloadTranscript open={open} handleClose={() => { setOpen(false) }} />
    </Card>
  );
}

export default MeetingCard;
interface DownloadTranscriptProps {
  open: boolean;
  handleClose: Function;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center'
};
const DownloadTranscript: FC<DownloadTranscriptProps> = (props: DownloadTranscriptProps) => {
  return <Modal
    open={props.open}
    onClose={() => props.handleClose()}
    aria-labelledby="parent-modal-title"
    aria-describedby="parent-modal-description"
  >
    <Box sx={{ ...style, width: 400 }}>
      <Typography sx={{ fontSize: 32, fontWeight: '400', marginBottom: 4 }}>Aguarde, gerando arquivo de transcrição</Typography>
      <CircularProgress />
    </Box>
  </Modal>
}