import React from "react";
import { createTheme } from "@mui/material/styles";
import {
  AppProvider,
  Branding,
  type Navigation,
} from "@toolpad/core/AppProvider";
import { Logo } from "static/images";
import { Tag, PaymentRounded } from "@mui/icons-material";
import { Outlet } from "react-router-dom";

const NAVIGATION: Navigation = [
  {
    segment: "#/dashboard",
    title: "Reuniões",
    icon: <Tag />,
    kind: "page",
  },

  {
    segment: "#/dashboard/configuracoes/cobranca",
    title: "Cobrança",
    icon: <PaymentRounded />,
  },
  {
    kind: "divider",
  },
];

const BRANDING: Branding = {
  logo: <img height="33px" width="auto" src={Logo} alt="ResumeTudo" />,
  title: "",
};

const themeResumeTudo = createTheme({
  cssVariables: {
    colorSchemeSelector: "data-toolpad-color-scheme",
  },
  colorSchemes: { light: true, dark: false },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default function DashboardResumeTudo() {
  return (
    <AppProvider
      navigation={NAVIGATION}
      theme={themeResumeTudo}
      branding={BRANDING}
      defaultDrawerOpen={false}
      // topbarComponents={[<AccountOptions user={user} imgUrl=''/>]}
    >
      <Outlet />
    </AppProvider>
  );
}
