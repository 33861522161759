import { useGlobalState } from "components/GlobalProvider";
import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

interface ProtectedRouteProps {
  children: JSX.Element;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const accessToken = localStorage.getItem("access_token");
  const { user, setUser } = useGlobalState();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      const storedUser = localStorage.getItem("user");
      if (storedUser) {
        setUser(JSON.parse(storedUser));
      }
    }
  }, []);
  
  // if (!accessToken) { 
  //   window.location = "#/" as unknown as Location;
  // }

  return children;
};