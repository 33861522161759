import React, { useEffect, useState } from "react";
import { Modal, Box, TextField, Button } from "@mui/material";
import { Download, Psychology } from "@mui/icons-material";
import { createMentalMap } from "utils/services";
import "./styles.scss";
import Loading from "components/Loading";

interface MentalMapProps {
  meetingId: string;
}

export const MentalMap: React.FC<MentalMapProps> = ({ meetingId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [context, setContext] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const toggleOpen = () => setIsOpen((old) => !old);

  const handleSubmit = () => {
    setIsLoading(true);

    createMentalMap(meetingId, context).then((response) => {
      const blob = new Blob([response.data], { type: "image/svg+xml" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `mapa_mental_${new Date()}.svg`;
      link.click();

      setTimeout(() => link.remove(), 10000);
      toggleOpen();
    });
  };

  useEffect(() => {
    if (!isOpen) {
      setContext("");
      setIsLoading(false);
    }
  }, [isOpen]);

  return (
    <>
      <button onClick={toggleOpen} className="mental-map-button">
        <Psychology />
        Mapa Mental
      </button>
      <Modal
        open={isOpen}
        onClose={toggleOpen}
        aria-labelledby="mental-map-modal"
        aria-describedby="mental-map-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <h2 id="mental-map-modal">Gerar mapa mental</h2>
          <p id="mental-map-description">Se quiser, especifique o que você gostaria que ele representasse:</p>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={context}
            onChange={(e) => setContext(e.target.value)}
            placeholder="Digite o contexto/foco aqui..."
            margin="normal"
          />
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button disabled={isLoading} onClick={toggleOpen} sx={{ mr: 1 }}>
              Cancelar
            </Button>
            <Button
              disabled={isLoading}
              onClick={handleSubmit}
              variant="contained"
            >
              {isLoading? <Loading size={20} /> : <Download />}
              Baixar
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
